import styled from "styled-components";

const BlogPostStyles = {
    Container: styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `,

    BlogPostInsideContainer: styled.div`
        max-width: 1800px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 60px;
    `,

    ArticleContainer: styled.div`
        width: 75%;
        margin-bottom: 80px;
        display: flex;
        flex-flow: column;
        /* border: 2px solid black; */
    `,

    TitleContainer: styled.div`
        font-size: 3rem;
        text-transform: uppercase;
        margin-bottom: 30px;
    `,

    ArticleBody: styled.div`
        font-size: 1.3rem;
    `,

    OtherPostsContainer: styled.div`
        width: 25%;
        /* border: 2px solid black; */
    `,
};

export default BlogPostStyles;
