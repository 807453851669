import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import BlogBG from "../components/Blog/Background/BlogBG";
import Layout from "../components/Layout/Layout";
import BlogPostStyles from "./blog-post.styles";

const {
    Container,
    BlogPostInsideContainer,
    ArticleContainer,
    TitleContainer,
    ArticleBody,
    OtherPostsContainer,
} = BlogPostStyles;

const BlogPost = ({ data }) => {
    const blogData = data.contentfulBlogPost;
    const image = getImage(blogData.thumbnail);
    return (
        <Layout>
            <BlogBG />
            <GatsbyImage image={image} alt="Main picutre of this blog post" />
            <Container>
                <BlogPostInsideContainer>
                    <ArticleContainer>
                        <TitleContainer>{blogData.title}</TitleContainer>
                        <ArticleBody>
                            {renderRichText(blogData.content)}
                        </ArticleBody>
                    </ArticleContainer>
                    <OtherPostsContainer></OtherPostsContainer>
                </BlogPostInsideContainer>
            </Container>
        </Layout>
    );
};

export default BlogPost;

export const query = graphql`
    query ($slug: String!) {
        contentfulBlogPost(slug: { eq: $slug }) {
            title
            content {
                raw
            }
            thumbnail {
                gatsbyImageData(
                    layout: FULL_WIDTH
                    height: 1600
                    cropFocus: CENTER
                    placeholder: DOMINANT_COLOR
                    quality: 100
                )
            }
        }
        allContentfulBlogPost {
            nodes {
                title
                id
                slug
                thumbnail {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        cropFocus: CENTER
                        aspectRatio: 1.333
                    )
                }
            }
        }
    }
`;
